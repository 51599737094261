.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.legend {
  font-size: small
}

.App-header select {
  margin: 10px;
  padding: 5px 10px;
  font-size: calc(10px + 2vmin);
}

.App-header button {
  margin: 5px;
  padding: 5px;
  font-size: calc(10px + 2vmin);
}

.slider {
  margin: 20px 0;
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;

}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 30px;
  background: #4CAF50;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}


button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

div .hidden {
  visibility: hidden;
}

.selected {
  background-color: #FFF;
  border-radius: 8px;  
  padding: 10px;
}

@media (max-height: 400px) {
  img {
    max-width: 45%;
  }

}